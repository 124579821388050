@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.responsive-image {
  width: 100%;
}
.responsive-image > div {
  position: unset !important;
}
.responsive-image img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* ----- Custom Gradients ------ */

.electric-blue-gradient {
  background-image: linear-gradient(
    to bottom right,
    #316ab2 11.25%,
    #79cbf3 39%,
    #4e8ac5 61.4%,
    #2378bd 78.4%,
    #316ab2 96.45%
  );
}

/* ----- Hover Link Underline ------ */
.link-hover-pink {
  @apply no-underline max-w-fit relative z-10 hover:before:-z-10 hover:before:absolute before:-bottom-1 before:left-0.5 before:h-0.5 before:w-full before:[background-image:_linear-gradient(to_right,#e22e89_70%,transparent)];
}

.link-pink {
  @apply no-underline max-w-fit relative before:-z-10 before:absolute before:-bottom-1 before:left-0.5 before:h-0.5 before:w-full before:[background-image:_linear-gradient(to_right,#e22e89_70%,transparent)];
}

/* ------ React Datepicker ------ */
.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded !important;
}

.react-datepicker__month-container {
  @apply flex flex-col !important;
}

.react-datepicker__month {
  @apply flex flex-col !important;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-selphGrey-800 !important;
}

.react-datepicker__week {
  @apply flex justify-around !important;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs !important;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  @apply absolute top-2 !important;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200  !important;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200  !important;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-selphGrey-800 rounded  !important;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent !important;
}

.react-datepicker__day--outside-month {
  @apply text-selphGrey-300 !important;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200 !important;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200  !important;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-selphBlue-300 !important;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-selphBlue-300 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  @apply text-white !important;
}

.react-datepicker__day--range-start {
  @apply bg-selphBlue-300 text-white hover:text-gray-700 hover:bg-white !important;
}

.react-datepicker__day--range-end {
  @apply bg-selphBlue-300 text-white hover:text-gray-700 hover:bg-white !important;
}

.react-datepicker__header__dropdown {
  @apply grid grid-flow-col justify-items-center mt-1 !important;
}

.react-datepicker__month-dropdown-container--select {
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  @apply block sm:text-sm rounded-lg m-0 w-full py-1 !important;
}

.react-datepicker__time-container {
  @apply right-[-85px] top-[-1px] !important;
}

.react-datepicker__triangle {
  @apply left-[-46%] !important;
}

.react-datepicker__header--has-time-select {
  @apply rounded-tr-none !important;
}

.react-datepicker__time-box {
  @apply w-full;
}
